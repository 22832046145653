<template>
    <div class="field-item" type="topic_add_edit">
	<table class="form-group">
		<tbody>
			<tr id="formTitle" v-if="detial.modelType != 'text'">
				<td width="50" class="c-grouptext">
					<span>题目</span>
				</td>
				<td class="control-panel bold_warp">
					<!-- <p id="topic" class="form-control text_edit" contenteditable="true">{{detial.topicName}}</p> -->
					<el-input id="topic" class="form-control text_edit" v-model="detial.topicName"></el-input>
					<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
					<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
				</td>
			</tr>
			<tr id="formRemark" v-if="detial.modelType != 'text'">
				<td width="50" class="c-grouptext">
					<span>备注</span>
				</td>
				<td class="control-panel bold_warp">
					<!-- <p id="remarks" class="form-control text_edit" contenteditable="true">{{detial.remarks}}</p> -->
					<el-input id="remarks" class="form-control text_edit" v-model="detial.remarks"></el-input>
					<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
					<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
				</td>
			</tr>
			<tr id="formType" v-if="currentType != 'text'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<el-select class="form_select" v-model="currentType" placeholder="请选择" disabled v-if="detial.parentType == 'personal'">
						<el-option :value="item.modelType" :label="item.modelTypeName" v-for="item in widgetList" :key="item._id"></el-option>
					</el-select>
					<el-select class="form_select" v-model="currentType" placeholder="请选择" @change="modelTypeUpdate" v-else>
						<el-option :value="item.modelType" :label="item.modelTypeName" v-for="item in widgetList" :key="item._id"></el-option>
					</el-select>
					<el-checkbox class="checkbox-text" id="topic_required" v-model="detial.topic_required">必填</el-checkbox>
					<input type="hidden" id="confirmType">
					<span class="area-tip" v-if="detial.modelType == 'lc-location'">注：详细省市列表可在保存后到前台查看，后台不支持显示。</span>
				</td>
			</tr>
			<!-- 单行文本题、多行文本题不显示 -->
			<tr id="formStyle0" v-if="detial.modelType == 'single_text'||detial.modelType == 'multiRow_text'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<table class="form_table set_score">
						<tr>
							<td>
								<el-select v-model="detial.scoreFormula">
									<el-option :value="0" label="回答与答案完全相同才得分"></el-option> 
									<el-option :value="1" label="回答中包含答案即可得分"></el-option> 
									<el-option :value="2" label="人工评分"></el-option>
								</el-select>
							</td>
						</tr>
						<tr v-if="detial.scoreFormula != 2">
							<td>
								<dl class="answerbox" v-for="(option,index) in detial.topicOption" :key="index">
									<dt>答案 <span class="score_div"></span></dt>
									<dd><el-input v-model="option.answer"></el-input></dd>
								</dl>
							</td>
						</tr>
						<tr>
							<td class="grossbox">
								<div class="score_gross">此题分值：<el-input class="form-control" v-model="detial.scoreGross" type="number"></el-input> 分</div>
							</td>
						</tr>
					</table>	
					<div class="bottom_btn">
						<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
						<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
					</div>
				</td>
			</tr>
			<!-- 单选题、下拉题、多选题,formStyle1显示 -->
			<tr id="formStyle1" v-if="detial.modelType == 'radio'||detial.modelType == 'select'||detial.modelType == 'checked'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<div class="topic_option">
						<table class="form_table" name="normal_table">
							<tr v-for="(item,index) in detial.topicOption" :key="index">
								<td>
									<div class="form_item bold_warp">
										<!-- <p class="form-control text_edit" contenteditable="true" name="topic_option" title="选项" @click="labelHide(this)" onblur="labelShow(this)"><span class="place_holder">{{item.value}}</span></p> -->
										<el-input class="form-control text_edit" name="topic_option" placeholder="选项" v-model="item.textVal" v-if="detial.modelType == 'checked'">
											<i slot="suffix" class="font-green" v-if="(detial.scoreFormula == 0 || detial.scoreFormula == 1) && item.isAnswer == 1">[正确答案]</i>
											<i slot="suffix" class="font-green" v-if="detial.scoreFormula == 2 && item.scoreItemN">{{item.scoreItemN}}</i>
										</el-input>
										<el-input class="form-control text_edit" name="topic_option" placeholder="选项" v-model="item.textVal" v-else>
											<i slot="suffix" class="font-green" v-if="detial.scoreFormula == 0 && item.isAnswer == 1">[正确答案]</i>
											<i slot="suffix" class="font-green" v-if="detial.scoreFormula == 1 && item.scoreItemN">{{item.scoreItemN}}</i>
										</el-input>
										<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
										<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
										<a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(index);">×</a>
									</div>
								</td>
							</tr>
						</table>
						<div class="add_option_btn">
							<a href="javascript: void(0);" @click="addTopicOption">新建选项</a>
						</div>
						<table class="form_table set_score" v-if="detial.modelType == 'radio'||detial.modelType == 'select'">
							<tr>
								<td>
									<el-select v-model="detial.scoreFormula">
										<el-option :value="0" label="此题有唯一答案和分值"></el-option> 
										<el-option :value="1" label="每个选项都有对应分值"></el-option> 
										<el-option :value="2" label="人工评分"></el-option>
									</el-select>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula == 0">
								<td>
									<el-select v-model="detial.answer" placeholder="请选择此题答案">
										<el-option v-for="(item,index) in detial.topicOption" :key="index" :value="item.textKey" :label="item.textVal"></el-option> 
									</el-select>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula != 1">
								<td class="grossbox">
									<div class="score_gross">此题分值：<el-input class="form-control" v-model="detial.scoreGross" type="number"></el-input> 分</div>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula == 1">
								<td>
									<dl class="answerbox">
										<dt>各选项分值(如需扣分请设置负分) <span class="score_div"></span></dt>
										<dd v-for="(item,index) in detial.topicOption" :key="index" class="">
											<div class="choice_item">{{item.textVal}}</div>
											<el-input class="form-control" v-model="item.optionScore" type="number"></el-input> 分
										</dd>
									</dl>
								</td>
							</tr>
						</table>
						<table class="form_table set_score" v-if="detial.modelType == 'checked'">
							<tr>
								<td>
									<el-select v-model="detial.scoreFormula">
										<el-option :value="0" label="全部答对才得分"></el-option> 
										<el-option :value="1" label="答对几项得几分，答错不得分"></el-option> 
										<el-option :value="2" label="每个选项都有对应的分值"></el-option>
										<el-option :value="3" label="人工评分"></el-option>
									</el-select>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula == 0">
								<td>
									<dl class="answerbox">
										<dd v-for="(item,index) in detial.topicOption" :key="index">
											<el-checkbox :label="item.textKey" v-model="detial.answer">{{item.textVal}}</el-checkbox>
										</dd>
									</dl>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula == 1">
								<td>
									<dl class="answerbox">
										<dt>各选项分值(如需扣分请设置负分) <span class="score_div"></span></dt>
										<dd v-for="(item,index) in detial.topicOption" :key="index">
											<el-checkbox class="choice_item" :label="item.textKey" v-model="detial.answer">{{item.textVal}}</el-checkbox>
											<el-input class="form-control" v-model="item.optionScore" type="number"></el-input> 分
										</dd>
									</dl>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula != 2">
								<td class="grossbox">
									<div class="score_gross">此题分值：<el-input class="form-control" v-model="detial.scoreGross" type="number"></el-input> 分</div>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula == 2">
								<td>
									<dl class="answerbox">
										<dt>各选项分值(如需扣分请设置负分) <span class="score_div"></span></dt>
										<dd v-for="(item,index) in detial.topicOption" :key="index">
											<div class="choice_item">{{item.textVal}}</div>
											<el-input class="form-control" v-model="item.optionScore" type="number"></el-input> 分
										</dd>
									</dl>
								</td>
							</tr>
						</table>
						<div class="bottom_btn">
							<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
							<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
						</div>
					</div>
				</td>
			</tr>
			<!-- 图片选择,formStyle7显示 -->
			<tr id="formStyle7" v-if="detial.modelType == 'images'||detial.modelType == 'imagesRadio'||detial.modelType == 'imagesChecked'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel bold_warp">
					<ul class="showimg_list">
						<li class="showimg_item" v-for="(item,index) in detial.topicOption" :key="index">
							<div class="showimg_box">
								<img :src="item.imgSrc">
								<div class="showimg_opbtn">
									<!-- <a href="javascript: void(0);" @click="editImgShape(index);">编辑</a> -->
									<a href="javascript: void(0);" @click="handleRemove(index);">删除</a>
								</div>
							</div>
							<div class="showimg_name">
								<el-radio :label="item.textKey" v-model="detial.value" disabled v-if="detial.modelType == 'imagesRadio'">
									<el-input class="ques_input" placeholder="名称" v-model="item.textVal"></el-input>
								</el-radio>
								<el-checkbox :label="item.textKey" v-model="detial.value" disabled v-if="detial.modelType == 'imagesChecked'">
									<el-input class="ques_input" placeholder="名称" v-model="item.textVal"></el-input>
								</el-checkbox>
							</div>
							<div class="score_div" v-if="detial.modelType == 'imagesChecked'">
								<i class="font-green" v-if="(detial.scoreFormula == 0 || detial.scoreFormula == 1) && item.isAnswer == 1">[正确答案]</i>
								<i class="font-green" v-if="detial.scoreFormula == 2 && item.scoreItemN">{{item.scoreItemN}}</i>
							</div>
							<div class="score_div" v-else>
								<i class="font-green" v-if="detial.scoreFormula == 0 && item.isAnswer == 1">[正确答案]</i>
								<i class="font-green" v-if="detial.scoreFormula == 1 && item.scoreItemN">{{item.scoreItemN}}</i>
							</div>
						</li>
						<li class="showimg_addbtn">
							<el-upload class="avatar-uploader" ref="pictureUpload" :show-file-list="false" multiple action="/com/uplaod_modelimg.do" :file-list="detial.topicOption" accept="image/*" list-type="picture-card" :before-upload="beforeImgUpload" :on-success="handleImgSuccess">
								<i class="el-icon-plus avatar-uploader-icon"></i>
								<span class="avatar-txt">请选择图片</span>
							</el-upload>
						</li>
					</ul>	
					<table class="form_table set_score" v-if="detial.modelType == 'imagesRadio'||detial.modelType == 'images'">
						<tr>
							<td>
								<el-select v-model="detial.scoreFormula">
									<el-option :value="0" label="此题有唯一答案和分值"></el-option> 
									<el-option :value="1" label="每个选项都有对应分值"></el-option> 
									<el-option :value="2" label="人工评分"></el-option>
								</el-select>
							</td>
						</tr>
						<tr v-if="detial.scoreFormula == 0">
							<td>
								<el-select v-model="detial.answer" placeholder="请选择此题答案">
									<el-option v-for="(item,index) in detial.topicOption" :key="index" :value="item.textKey" :label="item.textVal"></el-option> 
								</el-select>
							</td>
						</tr>
						<tr v-if="detial.scoreFormula != 1">
							<td class="grossbox">
								<div class="score_gross">此题分值：<el-input class="form-control" v-model="detial.scoreGross" type="number"></el-input> 分</div>
							</td>
						</tr>
						<tr v-if="detial.scoreFormula == 1">
							<td>
								<dl class="answerbox">
									<dt>各选项分值(如需扣分请设置负分) <span class="score_div"></span></dt>
									<dd v-for="(item,index) in detial.topicOption" :key="index" class="">
										<div class="choice_item">{{item.textVal}}</div>
										<el-input class="form-control" v-model="item.optionScore" type="number"></el-input> 分
									</dd>
								</dl>
							</td>
						</tr>
					</table>
					<table class="form_table set_score" v-if="detial.modelType == 'imagesChecked'">
						<tr>
							<td>
								<el-select v-model="detial.scoreFormula">
									<el-option :value="0" label="全部答对才得分"></el-option> 
									<el-option :value="1" label="答对几项得几分，答错不得分"></el-option> 
									<el-option :value="2" label="每个选项都有对应的分值"></el-option>
									<el-option :value="3" label="人工评分"></el-option>
								</el-select>
							</td>
						</tr>
						<tr v-if="detial.scoreFormula == 0">
							<td>
								<dl class="answerbox">
									<dd v-for="(item,index) in detial.topicOption" :key="index" class="">
										<el-checkbox :label="item.textKey" v-model="detial.answer">{{item.textVal}}</el-checkbox>
									</dd>
								</dl>
							</td>
						</tr>
						<tr v-if="detial.scoreFormula == 1">
							<td>
								<dl class="answerbox">
									<dt>各选项分值(如需扣分请设置负分) <span class="score_div"></span></dt>
									<dd v-for="(item,index) in detial.topicOption" :key="index" class="">
										<el-checkbox :label="item.textKey" v-model="detial.answer">{{item.textVal}}</el-checkbox>
										<el-input class="form-control" v-model="item.optionScore" type="number"></el-input> 分
									</dd>
								</dl>
							</td>
						</tr>
						<tr v-if="detial.scoreFormula != 2">
							<td class="grossbox">
								<div class="score_gross">此题分值：<el-input class="form-control" v-model="detial.scoreGross" type="number"></el-input> 分</div>
							</td>
						</tr>
						<tr v-if="detial.scoreFormula == 2">
							<td>
								<dl class="answerbox">
									<dt>各选项分值(如需扣分请设置负分) <span class="score_div"></span></dt>
									<dd v-for="(item,index) in detial.topicOption" :key="index" class="">
										<div class="choice_item">{{item.textVal}}</div>
										<el-input class="form-control" v-model="item.optionScore" type="number"></el-input> 分
									</dd>
								</dl>
							</td>
						</tr>
					</table>				
					<div class="bottom_btn">
						<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
						<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
					</div>
				</td>
			</tr>
			<!-- 多选填空,formStyle8显示 -->
			<tr id="formStyle8" v-if="detial.modelType == 'lc-blanks'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<div class="topic_option">
						<table class="form_table topic" name="normal_table">
							<tr v-for="(item,index) in detial.topicOption" :key="index">
								<td>
									<div class="form_item bold_warp">
										<el-input class="form-control text_edit" name="topic_option" placeholder="问题" v-model="item.textVal"></el-input>
										<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
										<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
										<a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(index);">×</a>
									</div>
								</td>
							</tr>
						</table>
						<div class="add_option_btn">
							<a href="javascript: void(0);" @click="addMatrixOption('blanks');">新建问题</a>
						</div>
						<table class="form_table set_score">
							<tr>
								<td>
									<el-select v-model="detial.scoreFormula">
										<el-option :value="0" label="全部答对才得分"></el-option> 
										<el-option :value="1" label="答对几项得几分"></el-option> 
										<el-option :value="2" label="人工评分"></el-option>
									</el-select>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula != 2">
								<td>
									<el-select v-model="detial.scoreAnswer">
										<el-option :value="0" label="回答与答案完全相同才得分"></el-option> 
										<el-option :value="1" label="回答中包含答案即可得分"></el-option> 
									</el-select>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula != 2">
								<td>
									<dl class="answerbox" v-for="(item,index) in detial.topicOption" :key="index">
										<dt>答案-填空{{index+1}}<span class="score_div"></span></dt>
										<dd>
											<el-input v-model="item.answerItemVal"></el-input>
										</dd>
										<dd v-if="detial.scoreFormula == 1">
											此项分值：<el-input class="form-control" v-model="item.optionScore" type="number"></el-input> 分
										</dd>
									</dl>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula != 1">
								<td class="grossbox">
									<div class="score_gross">此题分值：<el-input class="form-control" v-model="detial.scoreGross" type="number"></el-input> 分</div>
								</td>
							</tr>
						</table>
						<div class="bottom_btn">
							<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
							<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
						</div>
					</div>
				</td>
			</tr>
			<!-- 横向填空,formStyle9显示 -->
			<tr id="formStyle9" v-if="detial.modelType == 'lc-hblanks'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<div class="topic_option">
						<table class="form_table" name="normal_table">
							<tr v-for="(item,index) in detial.topicOption" :key="index">
								<td>
									<div class="form_item bold_warp hblank-txt">
										<el-input class="form-control text_edit" name="topic_option" placeholder="问题" v-model="item.textVal" @blur="changeValue"></el-input>
										<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
										<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
										<a class="set_blank" href="javascript: void(0);" @click="addtxtface(this,event);">...</a>
										<!-- <a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(this);">×</a> -->
										<div class="hide_val"></div>
									</div>
								</td>
							</tr>
						</table>
						<table class="form_table set_score">
							<tr>
								<td>
									<el-select v-model="detial.scoreFormula">
										<el-option :value="0" label="全部答对才得分"></el-option> 
										<el-option :value="1" label="答对几项得几分"></el-option> 
										<el-option :value="2" label="人工评分"></el-option>
									</el-select>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula != 2">
								<td>
									<el-select v-model="detial.scoreAnswer">
										<el-option :value="0" label="回答与答案完全相同才得分"></el-option> 
										<el-option :value="1" label="回答中包含答案即可得分"></el-option> 
									</el-select>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula != 2">
								<td v-for="(item,i) in detial.topicOption" :key="i">
									<dl class="answerbox" v-for="index in item.answerItemVal.length" :key="index">
										<dt>答案-填空{{index}}<span class="score_div"></span></dt>
										<dd>
											<el-input v-model="item.answerItemVal[index-1]"></el-input>
										</dd>
										<dd v-if="detial.scoreFormula == 1">
											此项分值：<el-input class="form-control" v-model="item.optionScore[index-1]" type="number"></el-input> 分
										</dd>
									</dl>
								</td>
							</tr>
							<tr v-if="detial.scoreFormula != 1">
								<td class="grossbox">
									<div class="score_gross">此题分值：<el-input class="form-control" v-model="detial.scoreGross" type="number"></el-input> 分</div>
								</td>
							</tr>
						</table>
						<div class="bottom_btn">
							<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
							<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detail: {
			type: Object,
		},
		widgetlist:{
			type: Array,
		}
	},
	emits:['confirm-add','close-add'],
	data(){
		return {
			isRests:false,
			detial:JSON.parse(JSON.stringify(this.detail)),
			currentType:this.detail.modelType,
			hblanksList:[],
			widgetList:JSON.parse(JSON.stringify(this.widgetlist)),
		}
	},
	created(){
		
	},
	watch: {
		'detial.topicOption': {
			handler(){
				if(this.detial.modelType == 'checked'||this.detial.modelType == 'imagesChecked'){
					if(this.detial.scoreFormula == 1){
						let score = 0;
						this.detial.answer.forEach(item => {
							this.detial.topicOption.forEach(option => {
								if(option.textKey == item){
									if(option.optionScore){
										option.scoreItemN = '(分值：' + option.optionScore + '分)';
										score += Number(option.optionScore);
									}
								}
							})
						})
						this.detial.scoreGross = score
					}else if(this.detial.scoreFormula == 2){
						let score = 0;
						this.detial.topicOption.forEach(option => {
							if(option.optionScore){
								option.scoreItemN = '(分值：' + option.optionScore + '分)';
								score += Number(option.optionScore);
							}
						})
						this.detial.scoreGross = score
					}
					
				}else if(this.detial.modelType == 'lc-blanks'){
					let score = 0;
					this.detial.topicOption.forEach(option=>{
						if(option.textVal.indexOf('editcont') > -1){
							const parser = new DOMParser();
							const doc = parser.parseFromString(option.textVal, 'text/html');
							const textNodes = doc.body.innerText.trim().split('\n');console.log(textNodes)
							option.textVal = textNodes[0]
						}
						if(!option.answerItemVal){
							option.answerItemVal ='';
						}
						if(!option.optionScore){
							option.optionScore = null;
						}
					})
					if(this.detial.scoreFormula == 1){
						this.detial.topicOption.forEach(option => {
							if(option.optionScore){
								score += Number(option.optionScore);
							}
						})
						this.detial.scoreGross = score
					}
				}else if(this.detial.modelType == 'lc-hblanks'){
					let score = 0;
					this.detial.topicOption.forEach(item=>{
						const parser = new DOMParser();
						const doc = parser.parseFromString(item.textHtmlVal, 'text/html');
						const textNodes = $(doc.body).find('input').length;
						console.log(item);
						item.answerItemVal.length = textNodes;
						item.optionScore.length = textNodes;
						for(var i=0;i<textNodes;i++){
							if(!item.answerItemVal[i]){
								item.answerItemVal[i] ='';
							}
							if(!item.optionScore[i]){
								item.optionScore[i] = '';
							}
						}
						this.$forceUpdate();
					})
					if(this.detial.scoreFormula == 1){
						this.detial.topicOption.forEach(option => {
							option.optionScore.forEach(item=>{
								if(item){
									score += Number(item);
								}
							})
						})
						this.detial.scoreGross = score
					}
				}else if(this.detial.modelType == 'select'||this.detial.modelType == 'radio'||this.detial.modelType == 'imagesRadio'){
					let score = 0;
					if(this.detial.scoreFormula == 1){
						this.detial.topicOption.forEach(option => {
							if(option.optionScore){
								option.scoreItemN = '(分值：' + option.optionScore + '分)';
								if(option.optionScore > score){
									score = Number(option.optionScore);
								}
							}
							
						})
						this.detial.scoreGross = score
					}else if(this.detial.scoreFormula == 0){
						this.detial.topicOption.forEach(option => {
							if(option.textKey == this.detial.answer){
								option.isAnswer = 1;
							}else{
								option.isAnswer = 0;
							}
						})
					}
					
				}
			},
			deep:true,
			immediate: true,
		},
		'detial.scoreFormula': {
			handler(){
				if(this.detial.modelType == 'checked'||this.detial.modelType == 'imagesChecked'){
					if(this.detial.scoreFormula == 1){
						let score = 0;
						this.detial.answer.forEach(item => {
							this.detial.topicOption.forEach(option => {
								if(option.textKey == item){
									if(option.optionScore){
										option.scoreItemN = '(分值：' + option.optionScore + '分)';
										score += Number(option.optionScore);
									}
								}
							})
						})
						this.detial.scoreGross = score
					}else if(this.detial.scoreFormula == 2){
						let score = 0;
						this.detial.topicOption.forEach(option => {
							if(option.optionScore){
								option.scoreItemN = '(分值：' + option.optionScore + '分)';
								score += Number(option.optionScore);
							}
						})
						this.detial.scoreGross = score
					}
					
				}else if(this.detial.modelType == 'select'||this.detial.modelType == 'radio'||this.detial.modelType == 'imagesRadio'){
					let score = 0;
					if(this.detial.scoreFormula == 1){
						this.detial.topicOption.forEach(option => {
							if(option.optionScore){
								option.scoreItemN = '(分值：' + option.optionScore + '分)';
								if(option.optionScore > score){
									score = Number(option.optionScore);
								}
							}
							
						})
						this.detial.scoreGross = score
					}else if(this.detial.scoreFormula == 0){
						this.detial.topicOption.forEach(option => {
							if(option.textKey == this.detial.answer){
								option.isAnswer = 1;
							}else{
								option.isAnswer = 0;
							}
						})
					}
					
				}
			},
			deep:true,
			immediate: true,
		},
		'detial.answer': {
			handler(){
				let answer = this.detial.answer;
				if(this.detial.modelType == 'checked'||this.detial.modelType == 'imagesChecked'){
					if(this.detial.scoreFormula == 0){
						this.detial.topicOption.forEach(option => {
							option.isAnswer = 0;
							this.detial.answer.forEach(item => {
								if(option.textKey == item){
									option.isAnswer = 1;
								}
							})
						})
					}else if(this.detial.scoreFormula == 1){
						let score = 0;
						this.detial.topicOption.forEach(option => {
							option.isAnswer = 0;
							this.detial.answer.forEach(item => {
								if(option.textKey == item){
									option.isAnswer = 1;
									if(option.optionScore){
										score += Number(option.optionScore);
									}
								}
							})
						})
						this.detial.scoreGross = score
					}
				}else if(this.detial.modelType == 'select'||this.detial.modelType == 'radio'||this.detial.modelType == 'imagesRadio'){
					this.detial.topicOption.forEach((item,index)=>{
						if(item.textKey == answer){
							this.detial.topicOption[index].isAnswer = 1;
						}else{
							this.detial.topicOption[index].isAnswer = 0;
						}
					})
				}
			},
			deep:true,
			immediate: true,
		}
	},
    methods:{
		// 选择题目类型
		modelTypeUpdate(e){
			let sort = this.detial.sort
			this.widgetList.forEach(widget=>{
				if(widget.modelType == e){
					this.detial = widget;
					this.detial.editState = true;
					this.detial.sort = sort;
					// if(e == 'select'||e == 'radio'||e == 'checked'||e == 'imagesRadio'||e == 'imagesChecked'||e == 'lc-blanks'||e == 'lc-table02'){
					// 	this.detial.topicOption.forEach((option,index)=>{
					// 		option['textKey'] = 'ques'+new Date().getTime()+index;
					// 	})
					// }
				}
			})
			// 重新获取类型的默认值，detial
		},
		changeValue(){
			let txt = this.detial.topicOption[0].textVal;
			if(txt.indexOf('_')>-1){
				let newStr = txt.match(/_+/g);
				let newCont = txt;
				let names = txt.split(/_+/g);
				let domCont = [];console.log(newStr,newCont,)
				newStr.forEach((item,index)=>{
					newCont = newCont.replace(item,'<em><input type="text" style="width:'+item.length*8+'px;"/></em>'); //将‘_’替换成input
					domCont.push({
						name:names[index],
						width:item.length*8+'px'
					})
				})
				this.detial.topicOption[0].textHtmlVal = newCont;
				this.detial.topicOption[0].domHtml = domCont;
			}else{
				this.$message.error("请填写准确的问题")
			}
		},
		// 编辑确定
        confirmAdd(){
			let optionError = 0;
			if(this.detial.modelType != 'text'){
				if(this.detial.label == ''){
					this.$message.error("题目不能为空！");
					return
				}
				if(this.detial.modelType == 'radio' || this.detial.modelType == 'select' || this.detial.modelType == 'checked'){
					let options = this.detial.topicOption
					let topicOption=[];
					options.forEach(option => {
						if(option.textVal == ''){
							optionError = 1;
							return
						}
						topicOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 2;
								return
							}
						})
						topicOption[topicOption.length]={
							textVal:option.textVal,
							rests:option.rests
						}
					});
					this.detial.topicOption = options;
					if(optionError == 1){
						this.$message.error("问题选项不能为空！");
						return
					}else if(optionError == 2){
						this.$message.error("问题选项不能重复！");
						return
					}
				}else if(this.detial.modelType == 'imagesRadio' || this.detial.modelType == 'imagesChecked'){
					let options = this.detial.topicOption
					let topicOption=[];
					options.forEach(option => {
						if(option.textVal == ''){
							optionError = 1;
							return
						}
						topicOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 2;
								return
							}
						})
						topicOption[topicOption.length]={
							textVal:option.textVal
						}
					});
					this.detial.topicOption = options;
					if(optionError == 1){
						this.$message.error("图片名称不能为空！");
						return
					}else if(optionError == 2){
						this.$message.error("图片名称不能重复！");
						return
					}
				}else if(this.detial.modelType == 'lc-blanks'){
					let topicOption=[];
					this.detial.topicOption.forEach(option => {
						if(option.textVal == ''){
							optionError = 1;
							return
						}
						topicOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 2;
								return
							}
						})
						topicOption[topicOption.length]={
							textVal:option.textVal
						}
					});
					if(optionError == 1){
						this.$message.error("问题不能为空！");
						return
					}else if(optionError == 2){
						this.$message.error("问题不能重复！");
						return
					}
				}
				
			}
			this.detial.editState = false;
			this.$emit('confirm-add',this.detial)
			
		},
		//关闭添加页面
		closeTopicAddEdit(){
			this.detial.editState = false;
			this.$emit('close-add',this.detial.editState)
		},
		// 新增选项
		addTopicOption(){
			let option = {
				textKey:'ques'+new Date().getTime(),
				textVal:''
			}
			this.detial.topicOption.push(option)
		},
		// 新增问题
		addMatrixOption(type){
			let option = {}
			if(type == 'blanks'){
				option = {
					textKey:'ques'+new Date().getTime(),
					textVal:'',
					textHtmlVal:'',
					answerItemVal:'',
					optionScore:''
				}
				this.detial.topicOption.push(option)
				this.detial.value.push('')
			}else{
				option = {
					textKey:'ques'+new Date().getTime(),
					textVal:''
				}
				this.detial.topicOption.push(option)
			}
			
		},
		//删除问题选项
		deleteTopicOption(index, stringType){
			if(stringType == '问题'){
				this.detial.quesOptions.splice(index,1)
				this.detial.value.splice(index,1)
			}else{
				this.detial.topicOption.splice(index,1)
			}
			
		},
		// 上传图片
		handleImgSuccess(res, file,filelist) {
			if(filelist.every(item=>item.status=='success')){
				filelist.map((item,index)=>{
					item.response && this.detial.topicOption.push({
						textKey:'ques'+new Date().getTime()+index,
						textVal:item.name,
						imgSrc:item.response.data.modelimg
					})
				})
			}
		},	
		// 上传前的校验
		beforeImgUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 5;
			if (!isLt2M) {
			this.$message.error('图片大小不能超过 5MB!');
			}
			return isLt2M;
		},
		handleRemove(index) {
			this.detial.topicOption.splice(index,1);
		},
		handleChange(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
	
    }
};
</script>
<style lang="less" scoped>
.field-item{
	margin-bottom: 5px;
	position: relative;
	border:1px solid transparent;
	&:hover{
		border:1px dashed var(--theme-color);
	}
	input[type=text],textarea{ 
		border:1px solid #e5e5e5;
		box-sizing: border-box;
		border-radius: 2px;
		font-size: 14px;
		color: #666;
		outline: none;
		resize: none;
	}
	input[type=text]:focus,textarea:focus{
		border-color:#0cf;
	}

	.form-group{
		width: 100%;
		td{
			padding:5px 10px;
		}
		#formType td{
			padding-bottom: 0;
		}
		.control-panel{
			.set_score{
				.answerbox{
					dt{
						line-height: 40px;
					}
					dd{
						line-height: 40px;
						padding:5px 0;
						.choice_item{
							float: left;
							width: 200px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
					
				}
				.form-control{
					width: 100px;
				}
			}
			.form_select{
				width: 140px;
			}
			.form_item{
				margin: 0 200px 0 0;
				position: relative;
			}
			.form-control,.linkage_title{
				width:100%;
				line-height: 40px;
				margin: 0;
				text-align: left;
			}
			/deep/ .input_underline{border:none;border-bottom:1px solid #555;margin-left:5px;}
			.place_holder{color: #999;}
			textarea.form-control{
				height: 60px;
			}
			.hblank-txt .form-control{
				padding:0;
				span{
					display: none;
				}
				input{
					border:none;
					width: 100%;
					height:40px;
				}
			}
			.set_bold,.upload_img,.set_blank {
				display: none;
				width: 30px;
				height: 30px;
				text-align: center;
				line-height: 30px;
				font-weight: bold;
				border: 1px solid #eee;
				font-size: 18px;
				color: #999!important;
				position: absolute;
				background: #fff;
				z-index: 9;
			}
			.set_bold {
				top: -22px;
				left: 10px;
			}
			.upload_img {
				top: -22px;
				left: 41px;
				background: url(../assets/images/form-icons.png) no-repeat -70px -45px #fff;
			}
			.upload_img {
				top: -22px;
				left: 72px;
			}
			
			.checkbox-text{
				margin-left: 20px;
			}
			.form_table{
				width: 100%;
				td{
					padding:5px 0;
				}
			}
			.deleteOption{
				position: absolute;
				right: -30px;top: 50%;
				color: #999;
				font-size: 24px;
				line-height: 1;
				transform: translateY(-50%);
			}
			.add_option_btn{
				margin: 0 200px 0 0;
				position: relative;
				a{
					display: block;
					height: 38px;
					line-height: 38px;
					border: 1px dashed #ddd;
					padding: 0 10px;
					margin:5px 0;
					color: #333;
				}
			}
			.add_other{
				a{
					color:#0cf;
				}
				.font-gray{
					color:#ccc;
					cursor: default;
				}
			}
			.bottom_btn{
				margin: 10px 0 0;
				text-align: center;
				.form-save{
					color: #fff;
					background: #0cf;
					border-color: #0cf;
					&:hover{
						color: #00ccff;
						background: #fff;
					}
				}
				.form-cancel{
					color: #555;
					&:hover{
						border-color: #C9C9C9;
					}
				}
			}
			.showimg_list{
				margin: 0 -5px;
				li{
					display: inline-block;
					width: 140px;
					min-height: 180px;
					border: 1px solid #DCDFE6;
					border-radius: 2px;
					padding: 0;
					margin: 5px 5px 20px;
					vertical-align: top;
					position: relative;
					background-color: #fff; 
					.showimg_box{
						width: 100%;
						height: 138px;
						border-bottom: 1px solid #DCDFE6;
						position: relative;
						overflow: hidden;
						background: #f5f5f5; 
						img{
							max-width: 100%;
							max-height: 100%;
							margin: auto;
							position: absolute;
							top: 0;right: 0;
							bottom: 0;left: 0;
						}
						.showimg_opbtn{
							display: none;
							position: absolute;
							left: 2px;
							right: 2px;
							bottom: 2px;
							font-size: 0;
							a{
								display: inline-block;
								width:100%;
								height: 24px;
								line-height: 24px;
								text-align: center;
								color: #fff;
								font-size: 12px;
								background-color: #484848;
								cursor: pointer;
							}
							a+a{
								margin-left: 2px;
							}
						}
						&:hover{
							.showimg_opbtn{
								display: block;
							}
						}
					}
					.showimg_name{
						font-size: 14px;
						padding:0 6px;
						white-space: nowrap;
						overflow: hidden;
						outline: none;
						cursor: pointer;
						/deep/ .el-input__inner{
							width: 100px;
							border:none;
							padding:0;
						}
					}
					.score_div{
						position: absolute;
						width: 100%;
						text-align: center;
						bottom: -20px;
						.font-green{
							color:var(--theme-green-color);
						}
					}
				}
				.showimg_addbtn{
					height: 180px;
					border:none;
					text-align: center;
					vertical-align: middle;
					font-size: 14px;
					cursor: pointer;
					.avatar-uploader{
						/deep/ .el-upload {
							position: relative;
							overflow: hidden;
							border-radius: 0;
							border-color: #DCDFE6;
							padding:40px 0;
							height: 100%;
							.avatar-uploader-icon {
								font-size: 28px;
								color: #bbb;
								height: 68px;
								line-height: 68px;
								text-align: center;
								display: block;
							}
							.avatar-txt {
								line-height: 30px;
								display: block;
								color:#bbb;
							}
							&:hover{
								border-color: var(--theme-custom-color);
								.avatar-uploader-icon,.avatar-txt{
									color: var(--theme-custom-color);
								}
							}
						}
					} 
				}
			}
		} 
	}
}

</style>